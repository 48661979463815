import React, { useContext, useState, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  StatusBar,
  TouchableOpacity,
  TouchableOpacityBase,
  Dimensions,
  ImageBackground,
} from "react-native";
import {
  ScrollView,
  TextInput,
  TouchableHighlight,
} from "react-native-gesture-handler";
import { color, concat } from "react-native-reanimated";
import { SafeAreaView } from "react-native-safe-area-context";
import Feather from "react-native-vector-icons/Feather";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { LinearGradient } from "expo-linear-gradient";
import { hp, wp } from "../assets/dimen";
import lang from "./lang";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
let getRandomColor = () => {
  return (
    "rgb(" +
    Math.floor(Math.random() * 256) +
    "," +
    Math.floor(Math.random() * 256) +
    "," +
    Math.floor(Math.random() * 256) +
    ")"
  );
};
export default function Home({ route, navigation }) {
  const [color1, setColo1] = useState("#1e2786");
  const [color2, setColo2] = useState("#4082e5");
  const [ln, setLn] = useState(route.params?.index ? lang.ar : lang.en);
  console.log([color1, color2]);

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      {/* header */}

      <View style={styles.header}>
        {/* logo */}
        <Image
          style={styles.logo}
          source={require("../assets/logo.png")}
        ></Image>
        {/* menu */}
        <View
          style={{
            flex: windowHeight > windowWidth ? 0.5 : 0.3,
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              justifyContent: "center",
              height: wp(100),
              borderBottomColor: "#F76D02",
              borderBottomWidth: wp(0),
            }}
            onPress={() =>
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: "Agrosource.org | HOME",
                    params: { index: ln.index },
                  },
                ],
              })
            }
          >
            <Text style={styles.menuText}>{ln.home}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              justifyContent: "center",
              height: wp(100),
              borderBottomColor: "#F76D02",
              borderBottomWidth: wp(6),
            }}
          >
            <Text
              style={[
                styles.menuText,
                {
                  paddingTop: wp(6),
                  color: "#000",
                },
              ]}
            >
              {ln.about}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              justifyContent: "center",
              height: wp(100),
              borderBottomColor: "#F76D02",
              borderBottomWidth: 0,
            }}
            onPress={() => setLn(ln.index ? lang.en : lang.ar)}
          >
            <Text style={styles.menuText}>{ln.lang}</Text>
          </TouchableOpacity>
        </View>
      </View>
      {/* custom requeset */}
      <View
        style={{
          backgroundColor: "#fff",
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          marginTop: wp(20),
        }}
      >
        <Image
          style={{
            position: "absolute",
            right: 0,
            bottom: wp(0),
            width: wp(600), //500
            height: wp(400),
            resizeMode: "contain",
          }}
          source={require("../assets/br.svg")}
        ></Image>
        <View
          style={{
            alignItems: "center",
            paddingVertical: wp(5),
          }}
        >
          <Text
            style={{
              fontSize: wp(30),
              fontWeight: "bold",
              alignSelf:
                windowWidth < windowHeight
                  ? ln.index
                    ? "flex-end"
                    : "flex-start"
                  : "center",
              marginHorizontal: windowWidth < windowHeight ? wp(20) : 0,
            }}
          >
            {ln.cust}
          </Text>
          <Text style={styles.subText}>{ln.subCust}</Text>
        </View>
        {/* The actual contact form  */}
        <View
          style={{
            flex: 0.9,
            justifyContent: "space-around",
            marginBottom: wp(30),
            paddingHorizontal: wp(15),
          }}
        >
          {/* name & email in a row view */}

          <View
            style={{
              flexDirection: windowHeight > windowWidth ? "column" : "row",
              alignItems: "center",
              marginVertical: wp(15),
            }}
          >
            <View
              style={{
                paddingRight: wp(windowHeight < windowWidth ? wp(30) : 0),
                marginVertical: wp(windowHeight < windowWidth ? 0 : 15),
              }}
            >
              <Text style={styles.textinput}>{ln.yourName}</Text>
              <TextInput style={styles.input}></TextInput>
            </View>
            <View>
              <Text style={styles.textinput}>{ln.email}</Text>
              <TextInput style={styles.input}></TextInput>
            </View>
          </View>
          {/* input Message */}
          <View>
            <Text style={styles.textinput}>{ln.msg}</Text>
            <TextInput
              multiline={true}
              style={[
                styles.input,
                {
                  height: wp(400),
                  width: windowHeight < windowWidth ? wp(630) : wp(300),
                  padding: wp(10),
                  marginBottom: wp(20),
                  backgroundColor: "#ffffff",
                },
              ]}
            ></TextInput>
            {/* Sending button */}
            <TouchableOpacity
              style={{
                height: wp(50),
                width: wp(90),
                backgroundColor: color2,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: wp(5),
                marginBottom: wp(windowHeight < windowWidth ? 0 : 15),
              }}
            >
              <Text style={{ color: "#fff", fontSize: wp(18) }}>{ln.send}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {/* foot */}
      <View
        style={{
          backgroundColor: "#000428",
          alignItems: "center",
        }}
      >
        <View
          style={{
            marginVertical: wp(20),
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#f1f1f1",
              fontSize: wp(25),
              fontWeight: "bold",
            }}
          >
            AGROSOURCE.ORG
          </Text>
          <Text
            style={{
              color: "#fff",
              fontSize: wp(17),
              lineHeight: wp(30),
              textAlign: "center",
            }}
          >
            +212702774119{"\n"}
            {/* +212606343434{"\n"} */}© 2021 AGROSOURCE.ORG, All rights
            reserved.
          </Text>
        </View>
      </View>
      {/* </ScrollView> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "space-between",
  },
  header: {
    zIndex: 10,
    flexDirection: windowHeight > windowWidth ? "column" : "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: windowHeight > windowWidth ? wp(200) : wp(100),
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  logo: {
    width: wp(190),
    height: wp(80),
    resizeMode: "contain",
  },
  menuText: {
    fontWeight: "bold",
    fontSize: wp(16),
    marginHorizontal: windowHeight > windowWidth ? wp(30) : wp(20),
    // color: "#1e2786",
  },
  headProduts: {
    width: wp(300),
    height: wp(450),
    backgroundColor: "#fff",
    margin: wp(30),
    borderRadius: wp(5),
    paddingVertical: wp(20),
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.03,
    shadowRadius: 3.84,
    elevation: 5,
  },
  subText: {
    fontSize: wp(16),
    paddingVertical: wp(10),
    paddingHorizontal: wp(20),
  },
  input: {
    height: wp(40),
    width: wp(300),
    borderWidth: wp(2),
    borderRadius: wp(5),
    borderColor: "#EFEFEF",
    padding: wp(8),
    marginTop: wp(5),
    fontSize: wp(18),
  },
  textinput: { padding: wp(5), fontSize: wp(14), fontWeight: "bold" },
  descImage: {
    resizeMode: "cover",
    height: wp(120),
    width: wp(120),
    borderRadius: wp(60),
    justifyContent: "center",
    alignSelf: "center",
  },
  textProduct: {
    marginHorizontal: wp(20),
    marginVertical: wp(10),
    color: "#000",
    fontSize: wp(18),
  },
  backImgProduct: {
    marginVertical: wp(20),
    alignSelf: "center",
    backgroundColor: "#f2f2f2",
    height: wp(130),
    width: wp(130),
    borderRadius: wp(75),
    justifyContent: "center",
  },
});
