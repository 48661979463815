import React, { useContext, useState, useEffect } from "react";
import {
  ImageBackground,
  Text,
  View,
  StyleSheet,
  Image,
  StatusBar,
  TouchableOpacity,
  TouchableOpacityBase,
  Dimensions,
} from "react-native";
import {
  ScrollView,
  TextInput,
  TouchableHighlight,
} from "react-native-gesture-handler";
import { color, concat } from "react-native-reanimated";
import { SafeAreaView } from "react-native-safe-area-context";
import Feather from "react-native-vector-icons/Feather";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { LinearGradient } from "expo-linear-gradient";
import { hp, wp } from "../assets/dimen";
import lang from "./lang";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
let getRandomColor = () => {
  return (
    "rgb(" +
    Math.floor(Math.random() * 256) +
    "," +
    Math.floor(Math.random() * 256) +
    "," +
    Math.floor(Math.random() * 256) +
    ")"
  );
};
export default function Home({ route, navigation }) {
  const [color1, setColo1] = useState("#fff"); //1e2786
  const [color2, setColo2] = useState("#f0f0f0"); //4082e5
  console.log([color1, color2]);
  const [ln, setLn] = useState(route.params?.index ? lang.ar : lang.en);
  console.log(route.params?.index);
  return (
    <View style={styles.container}>
      <SafeAreaView>
        <StatusBar style="auto" />
        {/* header */}

        <View style={styles.header}>
          {/* logo */}
          <Image
            style={styles.logo}
            source={require("../assets/logo.png")}
          ></Image>
          {/* menu */}
          <View
            style={{
              flex: windowHeight > windowWidth ? 0.5 : 0.3,
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                justifyContent: "center",
                height: wp(100),
                borderBottomColor: "#F76D02",
                borderBottomWidth: wp(6),
              }}
            >
              <Text
                style={[
                  styles.menuText,
                  {
                    paddingTop: wp(6),
                    color: "#000",
                  },
                ]}
              >
                {ln.home}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                height: wp(100),
                borderBottomColor: "#FFC100",
                borderBottomWidth: 0,
              }}
              onPress={() =>
                navigation.navigate("Agrosource.org | CONTACT US", {
                  index: ln.index,
                })
              }
            >
              <Text style={styles.menuText}>{ln.about}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                height: wp(100),
                borderBottomColor: "#FFC100",
                borderBottomWidth: 0,
              }}
              onPress={() => setLn(ln.index ? lang.en : lang.ar)}
            >
              <Text style={styles.menuText}>{ln.lang}</Text>
            </TouchableOpacity>
          </View>
        </View>
        {/* main adv */}
        <View
          style={{
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.1,
            shadowRadius: 3.84,
            elevation: 5,
            zIndex: 1,
            backgroundColor: "#000",
            minHeight: windowWidth < windowHeight ? 0 : wp(400),
          }}
        >
          <ImageBackground
            // blurRadius={5}
            source={{
              // uri: "https://source.unsplash.com/1600x900/?container,cargo",
              uri: "https://source.unsplash.com/sWOvgOOFk1g/1000x500",
            }}
            style={{ flex: 1 }}
          >
            <View
              style={{
                alignItems: "center",
                backgroundColor: "rgba(16,59,190,0.6)",
                flex: 1,
                justifyContent: "center",
                paddingVertical: wp(20),
              }}
            >
              <Text
                style={{
                  color: "#fff",
                  fontSize: wp(55),
                  fontWeight: "bold",
                  textAlign:
                    windowHeight > windowWidth
                      ? ln.index
                        ? "right"
                        : "left"
                      : "center",

                  paddingHorizontal: wp(20),
                }}
              >
                {ln.maintitle}
              </Text>

              <View
                style={{
                  marginVertical: wp(20),
                  alignContent: "left",
                }}
              >
                <Text
                  style={{
                    color: "#fbfbfb",
                    fontSize: wp(20),
                    paddingHorizontal: wp(20),
                  }}
                >
                  {ln.mainTitleBanner}
                  {"! \n"}
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  height: wp(50),
                  width: wp(150),
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: wp(75),
                  borderWidth: wp(2),
                  backgroundColor: "#BF4E6D",
                  borderColor: "#F76D02",
                }}
                onPress={() =>
                  navigation.navigate("Agrosource.org | CONTACT US", {
                    index: ln.index,
                  })
                }
              >
                <Text
                  style={{
                    fontSize: wp(18),
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  {ln.getStarted}
                </Text>
              </TouchableOpacity>
            </View>
          </ImageBackground>
        </View>
        {/* products */}
        <View
          style={{
            backgroundColor: "#f3f7f7",
            alignItems: "center",
          }}
        >
          <View
            style={{
              marginVertical: wp(20),
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: wp(30),
                fontWeight: "bold",
                alignSelf:
                  windowWidth < windowHeight
                    ? ln.index
                      ? "flex-end"
                      : "flex-start"
                    : "center",
                marginHorizontal: windowWidth < windowHeight ? wp(20) : 0,
              }}
            >
              {ln.products}
            </Text>
            <Text
              style={[
                styles.subText,
                { marginHorizontal: wp(20), alignSelf: "center" },
              ]}
            >
              {ln.subProducts}
            </Text>
            {/* this should be vertical or horizontal in case of mobile of web */}
            <View
              style={{
                flexDirection: windowWidth < windowHeight ? "column" : "row",
              }}
            >
              {/* product 1 */}
              <View style={styles.headProduts}>
                <Text
                  style={{
                    alignSelf: "center",
                    fontSize: wp(20),
                    fontWeight: "bold",
                  }}
                >
                  {ln.fruits}
                </Text>
                <View
                  style={{
                    padding: wp(5),
                    marginHorizontal: wp(20),
                    borderBottomColor: "#ffa73d",
                    borderBottomWidth: 2,
                  }}
                />
                <View style={styles.backImgProduct}>
                  <Image
                    style={styles.descImage}
                    source={require("../assets/fru.jpeg")}
                  ></Image>
                </View>
                <Text style={styles.textProduct}>Avocado</Text>
                <Text style={styles.textProduct}>Pinapple</Text>
                <Text style={styles.textProduct}>Bananas</Text>
                <Text style={styles.textProduct}>Mintes</Text>
                <Text style={styles.textProduct}>More...</Text>
              </View>
              {/* vegetables */}
              <View style={styles.headProduts}>
                <Text
                  style={{
                    alignSelf: "center",
                    fontSize: wp(20),
                    fontWeight: "bold",
                  }}
                >
                  {ln.vegs}
                </Text>
                <View
                  style={{
                    padding: wp(5),
                    marginHorizontal: wp(20),
                    borderBottomColor: "#17cf51",
                    borderBottomWidth: 2,
                  }}
                />
                <View style={styles.backImgProduct}>
                  <Image
                    style={styles.descImage}
                    source={require("../assets/vegi.jpeg")}
                  ></Image>
                </View>
                <Text style={styles.textProduct}>Onions</Text>
                <Text style={styles.textProduct}>Mushrooms</Text>
                <Text style={styles.textProduct}>Carrots</Text>
                <Text style={styles.textProduct}>Peppers</Text>
                <Text style={styles.textProduct}>More...</Text>
              </View>
              {/*Raw */}
              <View style={styles.headProduts}>
                <Text
                  style={{
                    alignSelf: "center",
                    fontSize: wp(20),
                    fontWeight: "bold",
                  }}
                >
                  {ln.misc}
                </Text>
                <View
                  style={{
                    padding: wp(5),
                    marginHorizontal: wp(20),
                    borderBottomColor: "#2617cf",
                    borderBottomWidth: 2,
                  }}
                />
                <View style={styles.backImgProduct}>
                  <Image
                    style={styles.descImage}
                    source={require("../assets/misc.jpg")}
                  ></Image>
                </View>
                <Text style={styles.textProduct}>Olive / Argan oil</Text>
                <Text style={styles.textProduct}>Moroccan pastries</Text>
                <Text style={styles.textProduct}>Sanitary products</Text>
                <Text style={styles.textProduct}>Cloths</Text>
                <Text style={styles.textProduct}>More...</Text>
              </View>
            </View>
          </View>
        </View>
        {/* custom requeset */}
        <LinearGradient
          // Button Linear Gradient
          colors={[color1, color2]}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            maxWidth: screen.width,
          }}
        >
          <View
            style={{
              margin: wp(20),
              maxWidth: wp(1000),
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: wp(30),
                fontWeight: "bold",
                alignSelf:
                  windowWidth < windowHeight
                    ? ln.index
                      ? "flex-end"
                      : "flex-start"
                    : "center",
                marginHorizontal: windowWidth < windowHeight ? wp(20) : 0,
              }}
            >
              {ln.titleAbout}
            </Text>
            {
              <Image
                style={{
                  width: Math.min(400, screen.width), //500
                  height: Math.min(200, screen.width),
                  resizeMode: "stretch",
                  marginVertical: wp(50),
                  maxWidth: screen.width,
                }}
                source={require("../assets/fr.svg")}
              ></Image>
            }
            <Text
              style={[
                styles.subText,
                { color: "#424953", marginHorizontal: wp(20) },
              ]}
            >
              {ln.weAre}
            </Text>
          </View>
        </LinearGradient>
        {/* foot */}
        <View
          style={{
            backgroundColor: "#000428",
            alignItems: "center",
          }}
        >
          <View
            style={{
              marginVertical: wp(20),
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                color: "#f1f1f1",
                fontSize: wp(25),
                fontWeight: "bold",
              }}
            >
              AGROSOURCE.ORG
            </Text>
            <Text
              style={{
                color: "#fff",
                fontSize: wp(17),
                lineHeight: wp(30),
                textAlign: "center",
              }}
            >
              +212702774119{"\n"}
              {/* +212606343434{"\n"} */}© 2021 AGROSOURCE.ORG, All rights
              reserved.
            </Text>
          </View>
        </View>
        {/* </ScrollView> */}
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f0f0f0",
    justifyContent: "space-between",
    maxWidth: screen.width,
  },
  header: {
    zIndex: 10,
    flexDirection: windowHeight > windowWidth ? "column" : "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: windowHeight > windowWidth ? wp(200) : wp(100),
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  logo: {
    width: wp(190),
    height: wp(80),
    resizeMode: "contain",
  },
  menuText: {
    fontWeight: "bold",
    fontSize: wp(16),
    marginHorizontal: windowHeight > windowWidth ? wp(30) : wp(20),
    // color: "#1e2786",
  },
  headProduts: {
    width: wp(300),
    height: wp(450),
    backgroundColor: "#fff",
    margin: wp(30),
    borderRadius: wp(5),
    paddingVertical: wp(20),
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.03,
    shadowRadius: 3.84,
    elevation: 5,
  },
  subText: {
    fontSize: wp(16),
    paddingVertical: wp(10),
  },
  input: {
    height: wp(40),
    width: wp(300),
    borderWidth: wp(2),
    borderRadius: wp(5),
    borderColor: "#EFEFEF",
    padding: wp(8),
    marginTop: wp(5),
    fontSize: wp(18),
  },
  textinput: { padding: wp(5), fontSize: wp(14), fontWeight: "bold" },
  descImage: {
    resizeMode: "cover",
    height: wp(120),
    width: wp(120),
    borderRadius: wp(60),
    justifyContent: "center",
    alignSelf: "center",
  },
  textProduct: {
    marginHorizontal: wp(20),
    marginVertical: wp(10),
    color: "#000",
    fontSize: wp(18),
  },
  backImgProduct: {
    marginVertical: wp(20),
    alignSelf: "center",
    backgroundColor: "#f2f2f2",
    height: wp(130),
    width: wp(130),
    borderRadius: wp(75),
    justifyContent: "center",
  },
});
