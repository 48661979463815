import React, { useContext, useState, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  StatusBar,
  TouchableOpacity,
  TouchableOpacityBase,
  Dimensions,
} from "react-native";
import {
  ScrollView,
  TextInput,
  TouchableHighlight,
} from "react-native-gesture-handler";
import { color, concat } from "react-native-reanimated";
import { SafeAreaView } from "react-native-safe-area-context";
import Feather from "react-native-vector-icons/Feather";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { LinearGradient } from "expo-linear-gradient";
import { hp, wp } from "../assets/dimen";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
let getRandomColor = () => {
  return (
    "rgb(" +
    Math.floor(Math.random() * 256) +
    "," +
    Math.floor(Math.random() * 256) +
    "," +
    Math.floor(Math.random() * 256) +
    ")"
  );
};
export default function Home({ navigation }) {
  const [color1, setColo1] = useState("#1e2786");
  const [color2, setColo2] = useState("#4082e5");
  console.log([color1, color2]);

  return (
    <View style={styles.container}>
      <SafeAreaView>
        <StatusBar style="auto" />
        {/* header */}

        <View style={styles.header}>
          {/* logo */}
          <Image
            style={styles.logo}
            source={require("../assets/logo.png")}
          ></Image>
          {/* menu */}
          <View
            style={{
              flex: windowHeight > windowWidth ? 0.5 : 0.3,
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                justifyContent: "center",
                height: wp(100),
                borderBottomColor: "#F76D02",
                borderBottomWidth: wp(0),
              }}
              onPress={() =>
                navigation.navigate("Home", {
                  // param1: 0,
                })
              }
            >
              <Text style={styles.menuText}>HOME</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                height: wp(100),
                borderBottomColor: "#F76D02",
                borderBottomWidth: wp(6),
              }}
            >
              <Text
                style={[
                  styles.menuText,
                  {
                    paddingTop: wp(6),
                    color: "#000",
                  },
                ]}
              >
                CATALOGUE
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                height: wp(100),
                borderBottomColor: "#F76D02",
                borderBottomWidth: 0,
              }}
              onPress={() =>
                navigation.navigate("About", {
                  // param1: 0,
                })
              }
            >
              <Text style={styles.menuText}>ABOUT US</Text>
            </TouchableOpacity>
          </View>
        </View>
        {/* foot */}
        <View
          style={{
            height: wp(200),
            backgroundColor: "#000428",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <View
            style={{
              marginVertical: wp(20),
            }}
          >
            <Text
              style={{
                color: "#f1f1f1",
                fontSize: wp(30),
                fontWeight: "bold",
              }}
            >
              AGROSOURCE.ORG
            </Text>
            <Text style={{ color: "#fff", fontSize: wp(17) }}>
              +212702774119{"\n"}© 2021 AGROSOURCE.ORG, All rights reserved.
            </Text>
          </View>
          {/* this is to ensure space around */}
          <View></View>
        </View>
        {/* </ScrollView> */}
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f0f0f0",
    justifyContent: "flex-start",
  },
  header: {
    zIndex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: wp(100),
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    elevation: 5,
  },
  logo: {
    width: wp(190),
    height: wp(80),
    resizeMode: "contain",
  },
  menuText: {
    fontWeight: "bold",
    fontSize: wp(16),
    marginHorizontal: wp(20),
  },
  headProduts: {
    width: wp(300),
    height: wp(450),
    backgroundColor: "#fff",
    margin: wp(30),
    borderRadius: wp(5),
    paddingVertical: wp(20),
  },
  subText: {
    fontSize: wp(16),
    paddingVertical: wp(10),
  },
  input: {
    height: wp(40),
    width: wp(300),
    borderWidth: wp(1),
    borderRadius: wp(5),
    borderColor: "#000",
    padding: wp(8),
    marginTop: wp(5),
    fontSize: wp(18),
  },
  textinput: { padding: wp(5), fontSize: wp(14), fontWeight: "bold" },
});
