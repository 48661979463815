import "react-native-gesture-handler";
import React, { useState, useEffect } from "react";
import { Text, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import {
  createStackNavigator,
  CardStyleInterpolators,
} from "@react-navigation/stack";
import home from "./components/Home";
import catalogue from "./components/Catalogue";
import about from "./components/About";
const Stack = createStackNavigator();
export default function App() {
  return (
    <NavigationContainer>
      <StackNavigator />
    </NavigationContainer>
  );
}

function StackNavigator({ navigation }) {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Agrosource.org | HOME"
        component={home}
        options={{
          cardStyleInterpolator:
            CardStyleInterpolators.forFadeFromBottomAndroid,
          headerShown: false,
          animationEnabled: true,
        }}
      />
      <Stack.Screen
        name="Agrosource.org | CONTACT US"
        component={about}
        options={{
          cardStyleInterpolator:
            CardStyleInterpolators.forFadeFromBottomAndroid,
          headerShown: false,
          animationEnabled: true,
        }}
      />
      <Stack.Screen
        name="Catalogue"
        component={catalogue}
        options={{
          cardStyleInterpolator:
            CardStyleInterpolators.forScaleFromCenterAndroid,
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}
