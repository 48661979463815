/*
 * Author: Ankur Gupta(ankurg22)
 */

import {
  widthPercentageToDP as wp2dp,
  heightPercentageToDP as hp2dp,
  listenOrientationChange as lor,
  removeOrientationListener as rol,
} from "react-native-responsive-screen";
import { Dimensions } from "react-native";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
/**
 * Width-Percentage
 * Converts width dimension to percentage
 * 360, 760 - design were made using this scale
 * @param dimension directly taken from design wireframes
 * @returns {string} percentage string e.g. '25%'
 */
export const wp = (dimension) => {
  return wp2dp(
    windowHeight < windowWidth
      ? (dimension / 1920) * 100 + "%"
      : (dimension / 400) * 100 + "%"
  );
};

/**
 * Height-Percentage
 * Converts width dimension to percentage
 * * 360, 760 - design were made using this scale
 * @param dimension directly taken from design wireframes
 * @returns {string} percentage string e.g. '25%'
 */
export const hp = (dimension) => {
  return hp2dp((dimension / windowWidth / 1.5) * 100 + "%");
};
