let lang = {
  en: {
    index: 0,
    home: "HOME",
    about: "CONTACT US",
    lang: "العربية",
    maintitle: "YOUR GATE TOWARDS\nALL MOROCCAN GOODS!",
    mainTitleBanner: "We ship any Moroccan product to any place in the world",
    getStarted: "Order Now",
    products: "PRODUCTS",
    subProducts:
      "You like moroccan products? Great! We have a selection of products that suit your need",
    fruits: "Fruits",
    vegs: "Vegetables",
    misc: "Miscellaneous",
    cust: "Custom Request",
    subCust:
      "You need a product that we don't list? fine, send us your request an we will work on it!",
    yourName: "Your Name",
    email: "Email",
    msg: "Message",
    send: "Send",
    // ----------------------ABOUT US PAGE---------------------------
    titleAbout: "ABOUT US",
    weAre:
      "We are an exporting company based in Morocco, we help facilitate the process of finding moroccan commodity. We are a leader in the fresh export business and  the only entity focused on fresh foods, vegetables and miscellaneous to export from Morocco to any country in the world.\nOur vision and mission is to optimize and facilitate the exportation between Morocco and the rest of the world respecting the two pillars which are quality and food safety. It testifies to our high standards, Food safety as countless rigorous controls are carried out at each stage of manufacturing from the raw material to the finished good secondly the quality department only releases a production after establishment of the analysis of all the manufactured batches. Thirdly, our permanent priority is the total satisfaction of our customers, which is why the culture of quality is a key value at agrosource.",
    //  " We are a leader in the fresh export business and  the only entity focused on fresh foods, vegetables and miscellaneous to export from Morocco to any country in the world. Our vision and mission is to optimize and facilitate the exportation between Morocco and the rest of the world respecting the two pillars which are quality and food safety. It testifies to our high standards, Food safety as countless rigorous controls are carried out at each stage of manufacturing from the raw material to the finished good secondly the quality department only releases a production after establishment of the analysis of all the manufactured batches. Thirdly, our permanent priority is the total satisfaction of our customers, which is why the culture of quality is a key value at agrosource.",
  },

  ar: {
    index: 1,
    home: "الرئيسية",
    about: "إتصل بنا",
    lang: "ENGLISH",
    maintitle: "طريقكم المضمون نحو \n جميع المنتجات المغربية",
    mainTitleBanner: "نقوم بتصدير جميع المنتجات المغربية نحو أي مكان بالعالم",
    getStarted: "إبدأ",
    products: "منتجات",
    subProducts:
      "هل تريد إستيراد المنتجات المغربية لبلدك؟ لدينا قائمة من البضائع المغربية التي نقوم بتصديرها",
    fruits: "فواكه",
    vegs: "خضر",
    misc: "منتجات متنوعة",
    cust: "طلب خاص",
    subCust:
      "لديك طلب لمنتوج معين؟ فضلا أرسل لنا تفاصيل طلبك وسنقوم بالرد عليك في أقرب وقت.",
    yourName: "إسمك",
    email: "بريدك الإلكتروني",
    msg: "رسالتك",
    send: "أرسل",
    // ----------------------ABOUT US PAGE---------------------------
    titleAbout: "عن الشركة",
    weAre:
      "نحن رواد في أعمال التصدير الطازجة ،  نحن الكيان الوحيد الذي يركز على الأطعمة الفواكه والخضروات الطازجة للتصدير بأي شكل من الأشكال في العالم  تتمثل رؤيتنا ورسالتنا في تحسين وتسهيل التصدير بين المغرب والعالم  مهمتنا هي احترام الركيزتين لميثاق الجودة / الزراعة لدينا.  تم اختباره وفقًا لمعاييرنا العالية: سلامة الغذاء: يتم تنفيذ العديد من الضوابط الصارمة في كل مرحلة من مراحل التصنيع ، من المواد الخام إلى المنتج النهائي.  تصدر إدارة الجودة فقط الإنتاج بعد التحقق من تحليلات جميع الدفعات.  الجودة: أولويتنا الدائمة هي الرضا التام لعملائنا ، وهذا هو السبب في أن ثقافة الجودة هي القيمة الأساسية في الزراعة",
  },
};
export default lang;
